import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectNotificationsSettingsState = createFeatureSelector<State>(
  'notificationsSettings',
);

export const selectNotificationsSettings = createSelector(
  selectNotificationsSettingsState,
  (state) => state.notificationsSettings,
);

export const selectNotificationsSettingsError = createSelector(
  selectNotificationsSettingsState,
  (state) => state.error,
);

export const selectNotificationsSettingsLoading = createSelector(
  selectNotificationsSettingsState,
  (state) => state.isLoading,
);
