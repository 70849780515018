import { createAction, props } from '@ngrx/store';

import { PropertyUser } from '../../models';

export const loadRequest = createAction(
  '[Property Group Users] Load Request',
  props<{ propertyGroupId: number }>(),
);

export const loadSuccess = createAction(
  '[Property Group Users] Load Success',
  props<{ propertyGroupUsers: PropertyUser[] }>(),
);

export const loadFailure = createAction(
  '[Property Group Users] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Property Group Users] Reset State');
