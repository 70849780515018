import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

const ICONS = {
  reservations: 'fa-suitcase-rolling',
  invoices: 'fa-file-alt',
  reservations_quotes: 'fa-file-signature',
  housekeeper: 'fa-broom',
  payments: 'fa-sack-dollar',
  system: 'fa-cogs',
  newsletters: 'fa-envelope',
};

function getIcon(slug: string) {
  return ICONS[slug.replace('-', '_')];
}

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { notificationsSettings }) => ({
    ...state,
    isLoading: false,
    error: null,
    notificationsSettings: {
      ...notificationsSettings,
      icon: getIcon(notificationsSettings.slug),
      sub_categories: notificationsSettings.sub_categories.map(
        (subcategory) => {
          return { ...subcategory, icon: getIcon(subcategory.slug) };
        },
      ),
    },
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(fromActions.updateSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function notificationsSettingsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
