import { createAction, props } from '@ngrx/store';

import { NotificationsSettings } from '../../models';

export const loadRequest = createAction(
  '[Notifications Settings] Load Request',
  props<{ propertyGroupId: number }>(),
);
export const loadSuccess = createAction(
  '[Notifications Settings] Load Success',
  props<{
    notificationsSettings: NotificationsSettings;
  }>(),
);
export const loadFailure = createAction(
  '[Notifications Settings] Load Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Notifications Settings] Update Request',
  props<{
    propertyGroupId: number;
    notificationsSettings: NotificationsSettings;
  }>(),
);
export const updateSuccess = createAction(
  '[Notifications Settings] Update Success',
);
export const updateFailure = createAction(
  '[Notifications Settings] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Notifications Settings] Reset State');
