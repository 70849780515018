import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { NotificationsSettings } from '../../models';
import { NotificationsSettingService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class NotificationsSettingsStoreEffects {
  constructor(
    private dataService: NotificationsSettingService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyGroupId }) =>
        this.dataService.load(propertyGroupId).pipe(
          map(
            ({
              data: [notificationsSettings],
            }: IResponseSuccess<NotificationsSettings[]>) =>
              fromActions.loadSuccess({
                notificationsSettings,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ propertyGroupId, notificationsSettings }) =>
        this.dataService.update(propertyGroupId, notificationsSettings).pipe(
          map(() => {
            this.notifications.success('update_success');
            return fromActions.updateSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );
}
